var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("footer", { staticClass: "footer-section section-box" }, [
    _c("div", { staticClass: "footer-content" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12" },
            [
              _c("div", { staticClass: "footer-items" }, [
                _vm._m(0),
                _vm._v(" "),
                _c("p", { staticClass: "font-13" }, [
                  _vm._v("The scenery shop that helps your creativity"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "socials" }, [
                  _c("a", { attrs: { href: _vm.applicationParams.fb_url } }, [
                    _c("i", { staticClass: "zmdi zmdi-facebook" }),
                  ]),
                  _vm._v(" "),
                  _c(
                    "a",
                    { attrs: { href: _vm.applicationParams.instagram_url } },
                    [_c("i", { staticClass: "zmdi zmdi-instagram" })]
                  ),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _vm._m(2),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12" },
            [
              _c("div", { staticClass: "footer-items" }, [
                _c("h4", [_vm._v("Newsletter")]),
                _vm._v(" "),
                _c("p", [_vm._v("Subscribe to our newsletter")]),
                _vm._v(" "),
                _c("div", { staticClass: "email" }, [
                  _c(
                    "div",
                    {
                      staticClass: "send",
                      on: { click: _vm.sendEmailAddress },
                    },
                    [_c("i", { staticClass: "zmdi zmdi-mail-send" })]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.userEmail,
                        expression: "userEmail",
                      },
                    ],
                    attrs: {
                      type: "email",
                      required: "",
                      pattern: "[^@]+@[^@]+.[a-zA-Z]{2,6}",
                      name: "email",
                      placeholder: "Your e-mail...",
                    },
                    domProps: { value: _vm.userEmail },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.userEmail = $event.target.value
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "font-13" }, [
                  _vm._v("@ 2024 Fotofonu / Ponomarys"),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "logo" }, [
      _c("a", { attrs: { href: "https://fotofonu.com/en" } }, [
        _c("img", {
          attrs: {
            src: "/images/header/fotofonu-logo-white.png",
            alt: "PonOmarys",
          },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12" },
      [
        _c("div", { staticClass: "footer-items footer-items-1" }, [
          _c("h4", [_vm._v("Contact")]),
          _vm._v(" "),
          _c("div", { staticClass: "contact" }, [
            _c("i", { staticClass: "zmdi zmdi-map" }),
            _vm._v(" "),
            _c("span", { staticClass: "font-13" }, [
              _vm._v("\n                Kyiv,\n                "),
              _c("br"),
              _vm._v("st. Zarichna 4 k 1,\n                "),
              _c("br"),
              _vm._v("02132\n              "),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "contact" }, [
            _c("i", { staticClass: "zmdi zmdi-phone" }),
            _vm._v(" "),
            _c("span", [
              _c(
                "a",
                { staticClass: "font-13", attrs: { href: "tel:380731515125" } },
                [_vm._v("+38 073 1515125")]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "contact" }, [
            _c("i", { staticClass: "zmdi zmdi-email" }),
            _vm._v(" "),
            _c("span", { staticClass: "font-13" }, [
              _vm._v("fotofonu@gmail.com"),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "contact" }, [
            _c("i", { staticClass: "zmdi zmdi-globe" }),
            _vm._v(" "),
            _c("span", { staticClass: "font-13" }, [
              _vm._v("www.fotofonu.com.ua"),
            ]),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12" },
      [
        _c("div", { staticClass: "footer-items footer-items-2" }, [
          _c("h4", [_vm._v("Profile")]),
          _vm._v(" "),
          _c("div", { staticClass: "profile" }, [
            _c("i", { staticClass: "zmdi zmdi-assignment" }),
            _vm._v(" "),
            _c("span", [
              _c(
                "a",
                {
                  staticClass: "font-13",
                  attrs: {
                    href: "https://fotofonu.com.ua/storage/images/zrazok_publichnoho_dohovoru_oferty.pdf",
                    target: "_blank",
                  },
                },
                [_vm._v("Public offer contract")]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "profile" }, [
            _c("i", { staticClass: "zmdi zmdi-account" }),
            _vm._v(" "),
            _c("span", [
              _c("a", { attrs: { href: "/en" } }, [_vm._v("My Account")]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "profile" }, [
            _c("i", { staticClass: "zmdi zmdi-shopping-cart" }),
            _vm._v(" "),
            _c("span", [
              _c("a", { attrs: { href: "/en" } }, [_vm._v("Checkout")]),
            ]),
          ]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }