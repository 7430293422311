
<template>
  <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
    <div class="widget-area">
      <!-- Search -->
      <div class="widget widget_search">
        <form class="search-form" method="get" role="search">
          <input
            type="search"
            v-model="search"
            @keyup.prevent="searchProducts"
            @keydown.enter.prevent="searchProducts"
            name="search"
            class="search-field"
            placeholder="Пошук..."
          />
          <button class="search-submit" type="submit">
            <i class="zmdi zmdi-search"></i>
          </button>
        </form>
      </div>
      <!-- Filter -->
      <div class="widget_price_filter">
        <h3 class="widget-title">Фільтр за ціною</h3>
        <form method="POST">
          <div class="price_slider_wrapper">
            <div class="price_slider ui-slider ui-slider-horizontal">
              <div id="slider-margin"></div>
            </div>
            <div class="price_slider_amount">
              <div class="price_label">
                Ціна:
                <span class="from" id="value-lower"></span>
                -
                <span class="to" id="value-upper"></span>
              </div>
              <!-- <button type="submit" class="button au-btn-black btn-small">Filter</button> -->
            </div>
          </div>
        </form>
      </div>
      <!-- Colors -->
      <div class="widget widget-color">
        <h3 class="widget-title">{{ colorOption.name }}</h3>
        <div class="widget-grid">
          <div
            :key="index"
            v-for="(value, index) in colorOption.values"
            @click="selectValue(value.id)"
            :class="{'selected-value': isSelectedValue(value.id)}"
          >
            <h3 class="widget-color_title">{{value.value}}</h3>
            <div class="widget-color_image-box">
              <img
                :src="value.image.url"
                class="widget-color_image"
                width="30"
                height="30"
                v-if="value.image !== null"
              />
              <div class="widget-color_icon">
                <img src="/images/check.png" alt="Fotofonu">
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Categories -->
      <div class="widget widget_product_categories">
        <h3 class="widget-title">Категорії</h3>
        <ul class="product-categories">
          <template v-for="(category, index) in categories">
            <li
              :key="index"
              @click.prevent="chooseCategory(category.id, category)"
              :class="{ 'cat-item--active' : category.id === currentCategory }"
              class="cat-item cat-parent"
            >
              <a href="#">
                <span>{{ category.title }}</span>
              </a>
              <a href="#">
                <span>{{ category.products_count }}</span>
              </a>
            </li>
            <div :key="index + 'subcont'" class="sub-cat-container">
              <template v-for="(subCategory, index) in category.children">
                <li
                  :key="index + 'sub'"
                  @click.prevent="chooseSubCategory(subCategory.id, subCategory)"
                  class="cat-item cat-child"
                  :class="{ 'cat-item--active subcat-item-active' : subCategory.id === currentSubCategory }"
                >
                  <a href="#">
                    <span>{{ subCategory.title }}</span>
                  </a>
                  <a href="#">
                    <span>{{ subCategory.products_count }}</span>
                  </a>
                </li>
                <div :key="index + 'undercat'" class="under-cat-container">
                  <li
                    @click.prevent="chooseUnderCategory(underCategory.id, underCategory)"
                    :key="index + 'sub'"
                    v-for="(underCategory, index) in subCategory.children"
                    class="cat-item cat-child"
                    :class="{ 'cat-item--active' : underCategory.id === currentUnderCategory }"
                  >
                    <a href="#">
                      <span>{{ underCategory.title }}</span>
                    </a>
                    <a href="#">
                      <span>{{ underCategory.products_count }}</span>
                    </a>
                  </li>
                </div>
              </template>
            </div>
          </template>
        </ul>
      </div>
      <!-- Products -->
      <div class="widgets widget_products">
        <h3 class="widget-title">Продукти</h3>
        <div :key="index" v-for="(product, index) in products" class="product_list_widget">
          <a :href="buildUrl(applicationParams.locale) + product.slug">
            <img
              v-bind:src="product.images.length ? product.images[0].url : defaultImage.url"
              v-bind:alt="product.images.length ? product.images[0].alt : defaultImage.alt"
            />
          </a>
          <!-- <div class="info" :class="{'margin-correctiom' : product.prices['RUB'].sale_price }"> -->
          <div class="info" :class="{'margin-correctiom' : product.prices['UAH'].sale_price }">
            <h5 class="product-title">
              <a :href="buildUrl(applicationParams.locale) + product.slug">{{ product.title }}</a>
            </h5>
            <span class="price">
              <!-- <div :class="{'crossed-out' : product.prices['RUB'].sale_price }"> -->
              <div :class="{'crossed-out' : product.prices['UAH'].sale_price }">
                <span class="woocommerce-Price-amount amount">
                  <span class="woocommerce-Price-currencySymbol">{{ activeCurrency.code }}</span>
                  {{ showItemPrice(product) }}
                </span>
              </div>
              <!-- <div v-if="product.prices['RUB'].sale_price"> -->
              <div v-if="product.prices['UAH'].sale_price">
                <span class="woocommerce-Price-amount amount">
                  <span class="woocommerce-Price-currencySymbol">{{ activeCurrency.code }}</span>
                  {{ showItemPrice(product, true) }}
                </span>
              </div>
            </span>
          </div>
        </div>
        <!-- <div class="product_list_widget">
                                        <a href="#">
                                            <img src="/images/shop-right-sidebar-2.jpg" alt="product">
                                        </a>
                                        <div class="info">
                                            <h5 class="product-title"><a href="#">Casia Side Table</a></h5>
                                            <span class="price">
                                                <span class="woocommerce-Price-amount amount">
                                                    <span class="woocommerce-Price-currencySymbol">$</span>246
                                                </span>
                                            </span>
                                        </div>
									</div>
									<div class="product_list_widget">
                                        <a href="#">
                                            <img src="/images/shop-right-sidebar-3.jpg" alt="product">
                                        </a>
                                        <div class="info">
                                            <h5 class="product-title"><a href="#">Low Table/Stool</a></h5>
                                            <span class="price">
                                                <span class="woocommerce-Price-amount amount">
                                                    <span class="woocommerce-Price-currencySymbol">$</span>29
                                                </span>
                                            </span>
                                        </div>
        </div>-->
      </div>
      <!-- Banner -->
      <div v-if="categoryBanner" class="widgets widget_banner">
        <a :href="categoryBanner.button_link" class="banner__link">
          <img
            :src="categoryBanner.image ? categoryBanner.image.url : defaultImage.url"
            :alt="categoryBanner.alt ? categoryBanner.image.alt : defaultImage.alt"
          />
          <div class="widget_banner-content">
            <span>{{ categoryBanner.title }}</span>
            <!-- <p>Awa Pendant Lamp</p> -->
            <a v-if="categoryBanner.button_text" href="shop-full-width.html">
              {{ categoryBanner.button_text }}
              <i class="zmdi zmdi-arrow-right"></i>
            </a>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
//Library for working with requests
import axios from "axios";

import { log } from "util";

import EventBus from "../../EventBus";

export default {
  name: "Sidebar",
  props: {
    categoryId: {
      type: Number,
      default: null
    },
    categoryBanner: {
      type: Object,
      default: null
    },
    colorOption: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      categories: [],
      currentCategory: null,
      currentSubCategory: null,
      currentUnderCategory: null,
      totalInCategory: [],
      updatePriceRange: true,
      products: [],
      priceRange: {
        priceMin: 0,
        priceMax: 1
      },
      search: "",
      activeCurrency: {},
      defaultImage: {
        url: "/images/ponomarys_default.jpg",
        alt: ""
      },
      selectedValues: []
    };
  },
  watch: {
    currentCategory: function(value) {
      this.currentSubCategory = null;
      this.currentUnderCategory = null;
    }
  },
  methods: {
    selectValue(value) {
      const valueIndex = this.selectedValues.indexOf(value);

      if (valueIndex === -1) {
        this.selectedValues.push(value);
      } else {
        this.selectedValues.splice(valueIndex, 1);
      }

      this.EventBus.$emit("selectedValues", this.selectedValues);
    },
    isSelectedValue(value) {
      return this.selectedValues.indexOf(value) !== -1;
    },
    showItemPrice(product, sale) {
      let prodPrice = 0;
      let salePrice = product.prices[this.activeCurrency.code].sale_price;
      let verCost = 0;

      if (product.prices[this.activeCurrency.code].price) {
        prodPrice = product.prices[this.activeCurrency.code].price;
      }

      if (sale === true && salePrice) {
        prodPrice = salePrice;
        console.log("salePrice: ", salePrice);
      }

      if (product.version_items.length) {
        for (let option of product.version_items) {
          verCost +=
            option.versions[0].version_prices[this.activeCurrency.code];
        }
        prodPrice += verCost;
      }

      return prodPrice;
    },

    setCurrency() {
      if (localStorage.getItem("selectedCurrency")) {
        this.activeCurrency = JSON.parse(
          localStorage.getItem("selectedCurrency")
        );
      } else {
        this.activeCurrency = applicationParams.currency;
        localStorage.setItem(
          "selectedCurrency",
          JSON.stringify(this.activeCurrency)
        );
      }
    },
    sendUpdatedRange(data) {
      EventBus.$emit("priceRangeUpdated", data);
    },
    updateSlider() {
      // let percents = Math.floor(
      //   ((+this.priceRange.priceMax - +this.priceRange.priceMin) * 20) / 100
      // );
      let marginSlider = document.getElementById("slider-margin");
      marginSlider.noUiSlider.updateOptions({
        start: [+this.priceRange.priceMin, +this.priceRange.priceMax],
        range: {
          min: +this.priceRange.priceMin,
          max: +this.priceRange.priceMax
        }
      });
    },
    setSlider(self) {
      /*noUiSlider*/

      let marginSlider = document.getElementById("slider-margin");
      if (marginSlider !== undefined) {
        noUiSlider.create(marginSlider, {
          start: [8, 20],
          margin: 0,
          step: 1,
          connect: true,
          range: {
            min: 0,
            max: 100
          },
          format: {
            from: function(value) {
              return parseInt(value, 10);
            },
            to: function(value) {
              return parseInt(value, 10);
            }
          }
        });

        // marginSlider.noUiSlider.on("set.one", function() {
        //   console.log(marginSlider.noUiSlider.get());
        // });

        /*Show the slider value*/
        var marginMin = document.getElementById("value-lower"),
          marginMax = document.getElementById("value-upper");

        marginSlider.noUiSlider.on("update", function(values, handle) {
          if (handle) {
            marginMax.innerHTML =
              self.activeCurrency.code + values[handle] + " ";
          } else {
            marginMin.innerHTML = self.activeCurrency.code + values[handle];
          }
        });
        marginSlider.noUiSlider.on("set", function(values, handle) {
          if (handle) {
            self.priceRange.priceMax = values[handle];
          } else {
            self.priceRange.priceMin = values[handle];
          }
          self.sendUpdatedRange(self.priceRange);
        });
      }
      /*End noUiSlider*/
    },
    getProducts() {
      let requestUrl = this.API.product.list;
      let requestParams = {
        category_id: 3,
        per_page: 3,
        currency_code: this.activeCurrency.code,
        locale: this.applicationParams.locale
      };

      axios.get(requestUrl, { params: requestParams }).then(response => {
        this.products = response.data.data;
      });
    },
    getCategories() {
      let requestUrl = this.API.category.list;

      let requestParams = {
        locale: this.applicationParams.locale
      };

      axios.get(requestUrl, { params: requestParams }).then(response => {
        this.categories = response.data.data;
        for (let i = 0; i < this.categories.length; i++) {
          let requestUrl = this.API.product.list;
          let requestParams = {
            category_id: this.categories[i].id,
            locale: this.applicationParams.locale
          };
          console.log("requestParams: ", requestParams);

          axios.get(requestUrl, { params: requestParams }).then(response => {
            this.totalInCategory.push(response.data.total);
          });
        }
      });
    },
    chooseCategory(data, category) {
      this.updatePriceRange = true;
      this.currentCategory = data;
      this.currentSubCategory = null;
      EventBus.$emit("categoryChanged", data);
      EventBus.$emit("categorySelected", category);
      this.scrollToTop();
    },
    chooseSubCategory(data, category) {
      this.currentSubCategory = data;
      EventBus.$emit("categoryChanged", data);
      EventBus.$emit("categorySelected", category);
      this.scrollToTop();
    },
    scrollToTop() {
      window.scrollTo(0, 0);
      console.log("Scroll");
    },
    chooseUnderCategory(data, category) {
      this.currentUnderCategory = data;
      EventBus.$emit("categoryChanged", data);
      EventBus.$emit("categorySelected", category);
    },
    searchProducts() {
      EventBus.$emit("searchProducts", this.search);
    },
    listenToPriceRange() {
      EventBus.$on("setPriceRange", data => {
        if (this.updatePriceRange) {
          this.priceRange.priceMin = data[0];
          this.priceRange.priceMax = data[1];
          this.updateSlider();
          this.updatePriceRange = false;
        }
      });
    },
    setDefaultImage() {
      this.defaultImage.url = this.API.resources.defaultImages.product;
      this.defaultImage.alt = "Default image";
    }
  },
  mounted() {
    this.currentCategory = this.categoryId;

    // Set currancy
    this.setCurrency();
    //Listen to price range updates
    this.listenToPriceRange();

    // Initialize slider
    this.setSlider(this);

    //Get categories
    this.getCategories();

    //Get products
    this.getProducts();

    // Set default image
    this.setDefaultImage();
  }
};
</script>

<style lang="scss" scoped>
.margin-correctiom {
  margin-top: -97px !important;
}
.cat-item--active {
  font-weight: 700;
}
.cat-child {
  padding-left: 20px;
}
.sub-cat-container {
  display: none;
}
.cat-item--active + .sub-cat-container {
  display: block;
}

.under-cat-container {
  display: none;
  padding-left: 20px;
}

.subcat-item-active + .under-cat-container {
  display: block;
}

.widget_banner {
  position: relative;
}

.widget_banner-content {
  position: absolute;
  padding: 5px 0 10px;
  left: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: #fff;
  min-height: 25%;
  width: 100%;
  box-shadow: 0px 0px 7px rgba($color: #000000, $alpha: 0.2);
  // box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);
}
</style>