var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12" },
    [
      _c("div", { staticClass: "widget-area" }, [
        _c("div", { staticClass: "widget widget_search" }, [
          _c(
            "form",
            {
              staticClass: "search-form",
              attrs: { method: "get", role: "search" },
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.search,
                    expression: "search",
                  },
                ],
                staticClass: "search-field",
                attrs: {
                  type: "search",
                  name: "search",
                  placeholder: "Пошук...",
                },
                domProps: { value: _vm.search },
                on: {
                  keyup: function ($event) {
                    $event.preventDefault()
                    return _vm.searchProducts.apply(null, arguments)
                  },
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    $event.preventDefault()
                    return _vm.searchProducts.apply(null, arguments)
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.search = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _vm._m(0),
            ]
          ),
        ]),
        _vm._v(" "),
        _vm._m(1),
        _vm._v(" "),
        _c("div", { staticClass: "widget widget-color" }, [
          _c("h3", { staticClass: "widget-title" }, [
            _vm._v(_vm._s(_vm.colorOption.name)),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "widget-grid" },
            _vm._l(_vm.colorOption.values, function (value, index) {
              return _c(
                "div",
                {
                  key: index,
                  class: { "selected-value": _vm.isSelectedValue(value.id) },
                  on: {
                    click: function ($event) {
                      return _vm.selectValue(value.id)
                    },
                  },
                },
                [
                  _c("h3", { staticClass: "widget-color_title" }, [
                    _vm._v(_vm._s(value.value)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "widget-color_image-box" }, [
                    value.image !== null
                      ? _c("img", {
                          staticClass: "widget-color_image",
                          attrs: {
                            src: value.image.url,
                            width: "30",
                            height: "30",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._m(2, true),
                  ]),
                ]
              )
            }),
            0
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "widget widget_product_categories" }, [
          _c("h3", { staticClass: "widget-title" }, [_vm._v("Категорії")]),
          _vm._v(" "),
          _c(
            "ul",
            { staticClass: "product-categories" },
            [
              _vm._l(_vm.categories, function (category, index) {
                return [
                  _c(
                    "li",
                    {
                      key: index,
                      staticClass: "cat-item cat-parent",
                      class: {
                        "cat-item--active": category.id === _vm.currentCategory,
                      },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.chooseCategory(category.id, category)
                        },
                      },
                    },
                    [
                      _c("a", { attrs: { href: "#" } }, [
                        _c("span", [_vm._v(_vm._s(category.title))]),
                      ]),
                      _vm._v(" "),
                      _c("a", { attrs: { href: "#" } }, [
                        _c("span", [_vm._v(_vm._s(category.products_count))]),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      key: index + "subcont",
                      staticClass: "sub-cat-container",
                    },
                    [
                      _vm._l(category.children, function (subCategory, index) {
                        return [
                          _c(
                            "li",
                            {
                              key: index + "sub",
                              staticClass: "cat-item cat-child",
                              class: {
                                "cat-item--active subcat-item-active":
                                  subCategory.id === _vm.currentSubCategory,
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.chooseSubCategory(
                                    subCategory.id,
                                    subCategory
                                  )
                                },
                              },
                            },
                            [
                              _c("a", { attrs: { href: "#" } }, [
                                _c("span", [_vm._v(_vm._s(subCategory.title))]),
                              ]),
                              _vm._v(" "),
                              _c("a", { attrs: { href: "#" } }, [
                                _c("span", [
                                  _vm._v(_vm._s(subCategory.products_count)),
                                ]),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              key: index + "undercat",
                              staticClass: "under-cat-container",
                            },
                            _vm._l(
                              subCategory.children,
                              function (underCategory, index) {
                                return _c(
                                  "li",
                                  {
                                    key: index + "sub",
                                    staticClass: "cat-item cat-child",
                                    class: {
                                      "cat-item--active":
                                        underCategory.id ===
                                        _vm.currentUnderCategory,
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.chooseUnderCategory(
                                          underCategory.id,
                                          underCategory
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("a", { attrs: { href: "#" } }, [
                                      _c("span", [
                                        _vm._v(_vm._s(underCategory.title)),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("a", { attrs: { href: "#" } }, [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(underCategory.products_count)
                                        ),
                                      ]),
                                    ]),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ]
                      }),
                    ],
                    2
                  ),
                ]
              }),
            ],
            2
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "widgets widget_products" },
          [
            _c("h3", { staticClass: "widget-title" }, [_vm._v("Продукти")]),
            _vm._v(" "),
            _vm._l(_vm.products, function (product, index) {
              return _c(
                "div",
                { key: index, staticClass: "product_list_widget" },
                [
                  _c(
                    "a",
                    {
                      attrs: {
                        href:
                          _vm.buildUrl(_vm.applicationParams.locale) +
                          product.slug,
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: product.images.length
                            ? product.images[0].url
                            : _vm.defaultImage.url,
                          alt: product.images.length
                            ? product.images[0].alt
                            : _vm.defaultImage.alt,
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "info",
                      class: {
                        "margin-correctiom": product.prices["UAH"].sale_price,
                      },
                    },
                    [
                      _c("h5", { staticClass: "product-title" }, [
                        _c(
                          "a",
                          {
                            attrs: {
                              href:
                                _vm.buildUrl(_vm.applicationParams.locale) +
                                product.slug,
                            },
                          },
                          [_vm._v(_vm._s(product.title))]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "price" }, [
                        _c(
                          "div",
                          {
                            class: {
                              "crossed-out": product.prices["UAH"].sale_price,
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "woocommerce-Price-amount amount",
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "woocommerce-Price-currencySymbol",
                                  },
                                  [_vm._v(_vm._s(_vm.activeCurrency.code))]
                                ),
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.showItemPrice(product)) +
                                    "\n                "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        product.prices["UAH"].sale_price
                          ? _c("div", [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "woocommerce-Price-amount amount",
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "woocommerce-Price-currencySymbol",
                                    },
                                    [_vm._v(_vm._s(_vm.activeCurrency.code))]
                                  ),
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(_vm.showItemPrice(product, true)) +
                                      "\n                "
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]
                  ),
                ]
              )
            }),
          ],
          2
        ),
        _vm._v(" "),
        _vm.categoryBanner
          ? _c("div", { staticClass: "widgets widget_banner" }, [
              _c(
                "a",
                {
                  staticClass: "banner__link",
                  attrs: { href: _vm.categoryBanner.button_link },
                },
                [
                  _c("img", {
                    attrs: {
                      src: _vm.categoryBanner.image
                        ? _vm.categoryBanner.image.url
                        : _vm.defaultImage.url,
                      alt: _vm.categoryBanner.alt
                        ? _vm.categoryBanner.image.alt
                        : _vm.defaultImage.alt,
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "widget_banner-content" }, [
                    _c("span", [_vm._v(_vm._s(_vm.categoryBanner.title))]),
                    _vm._v(" "),
                    _vm.categoryBanner.button_text
                      ? _c("a", { attrs: { href: "shop-full-width.html" } }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.categoryBanner.button_text) +
                              "\n              "
                          ),
                          _c("i", { staticClass: "zmdi zmdi-arrow-right" }),
                        ])
                      : _vm._e(),
                  ]),
                ]
              ),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      { staticClass: "search-submit", attrs: { type: "submit" } },
      [_c("i", { staticClass: "zmdi zmdi-search" })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "widget_price_filter" }, [
      _c("h3", { staticClass: "widget-title" }, [_vm._v("Фільтр за ціною")]),
      _vm._v(" "),
      _c("form", { attrs: { method: "POST" } }, [
        _c("div", { staticClass: "price_slider_wrapper" }, [
          _c(
            "div",
            { staticClass: "price_slider ui-slider ui-slider-horizontal" },
            [_c("div", { attrs: { id: "slider-margin" } })]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "price_slider_amount" }, [
            _c("div", { staticClass: "price_label" }, [
              _vm._v("\n                Ціна:\n                "),
              _c("span", { staticClass: "from", attrs: { id: "value-lower" } }),
              _vm._v("\n                -\n                "),
              _c("span", { staticClass: "to", attrs: { id: "value-upper" } }),
            ]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "widget-color_icon" }, [
      _c("img", { attrs: { src: "/images/check.png", alt: "Fotofonu" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }